import type { UserAuthConnection } from '@mntn-dev/domain-types'

/**
 * Redirects the user to the Identity Provider (IdP) login page.
 * @param authConnection - The login method to use for authentication.
 * @param loginHint - A hint to the IdP about the user's email address or username.
 */
export const redirectToIdp = (
  w: Window & typeof globalThis,
  authConnection: UserAuthConnection,
  loginHint: string
) => {
  const encodedLoginHint = encodeURIComponent(loginHint)
  w.location.href = `/api/auth/login?connection=${authConnection}&loginHint=${encodedLoginHint}`
}
