import type React from 'react'

type LoginBoxBodyProps = Readonly<{
  children: React.ReactNode
}>

const LoginBoxBody = ({ children }: LoginBoxBodyProps) => (
  <div className="shrink-0 text-center text-sm font-normal">{children}</div>
)

export { LoginBoxBody, type LoginBoxBodyProps }
