/**
 * Separates out the function used to handle form input on the login landing page.
 * It is separated out to make it easier to test.
 */

import { z } from 'zod'

import { logger } from '@mntn-dev/logger'

import type { TrpcFetchClient } from '~/app/_trpc/trpc-react-client.ts'
import { fetchUserAuthConnection } from './fetch-user.ts'
import { redirectToIdp } from './redirect-to-idp.ts'

export const formSchema = ({
  restrictedLogins = [],
  emailErrorMessage,
}: {
  restrictedLogins?: Array<string>
  emailErrorMessage: string
}) => {
  const emailAddress = z
    .string()
    .email()
    .refine(
      (data) =>
        !restrictedLogins.length ||
        restrictedLogins.some(
          (restrictedLogin) =>
            (restrictedLogin.startsWith('@') &&
              data.endsWith(restrictedLogin)) ||
            restrictedLogin === data
        ),
      emailErrorMessage
    )

  return z.object({ emailAddress })
}

export type FormSchemaType = z.infer<ReturnType<typeof formSchema>>

export const onSubmit = (
  trpcFetchClient: TrpcFetchClient,
  data: FormSchemaType
) => {
  return workForOnSubmit(trpcFetchClient, data).catch((err: unknown) => {
    logger.error('Unknown error while logging in:', { err })
    throw err
  })
}

export const workForOnSubmit = async (
  trpcFetchClient: TrpcFetchClient,
  data: { emailAddress: string }
) => {
  const { authConnection } = await fetchUserAuthConnection(
    trpcFetchClient,
    data.emailAddress
  )

  redirectToIdp(window, authConnection, data.emailAddress)
}
