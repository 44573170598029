import { cn } from '@mntn-dev/ui-utilities'

import { themeTextColorMap } from '@mntn-dev/ui-theme'

type LoginBoxHeaderProps = Readonly<{
  image?: {
    alt: string
    url: string
  }
  subtitle?: string
  title: string
}>

const LoginBoxHeader = ({ image, subtitle, title }: LoginBoxHeaderProps) => (
  <header className="pb-6 text-center">
    {image && (
      <img
        className="drop-shadow-blur mx-auto my-0 block h-[3.75rem] max-h-[3.75rem] max-w-full object-contain"
        src={image.url}
        alt={image.alt}
      />
    )}
    <h1
      className={cn(
        `font-heading mt-6 text-center text-[24px] font-normal ${themeTextColorMap.primary}`,
        { 'mb-4': !!subtitle }
      )}
    >
      {title}
    </h1>
    {subtitle && (
      <div
        className={`font-heading text-center text-sm font-normal leading-normal ${themeTextColorMap.secondary}`}
      >
        <p className="inline">{subtitle}</p>
      </div>
    )}
  </header>
)

export { LoginBoxHeader, type LoginBoxHeaderProps }
