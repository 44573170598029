import type React from 'react'

type LoginBoxFormFieldProps = {
  children: React.ReactNode
}

const LoginBoxFormField = ({ children }: LoginBoxFormFieldProps) => (
  <div role="group" className="mb-6 flex flex-col last:mb-0">
    {children}
  </div>
)

export { LoginBoxFormField, type LoginBoxFormFieldProps }
