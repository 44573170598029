import type React from 'react'

type LoginBoxFormFooterProps = Readonly<{
  children: React.ReactNode
}>

const LoginBoxFormFooter = ({ children }: LoginBoxFormFooterProps) => (
  <div className="mt-6">{children}</div>
)

export { LoginBoxFormFooter, type LoginBoxFormFooterProps }
