import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'
import {
  type InputHTMLAttributes,
  type RefAttributes,
  forwardRef,
  useState,
} from 'react'

import { mergeRefs } from '@mntn-dev/utilities'

type InputProps = Readonly<
  InputHTMLAttributes<HTMLInputElement> &
    RefAttributes<HTMLInputElement> & {
      hasError?: boolean
      label: string
      noJs?: boolean
    }
>

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { hasError, label, noJs = true, onBlur, ...inputProps }: InputProps,
    ref
  ) => {
    const [filled, setFilled] = useState(false)

    const internalRef = (el: HTMLInputElement | null) => {
      setFilled(!!el?.value)
    }

    const mergedRef = mergeRefs(internalRef, ref)

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFilled(!!e.target.value)

      if (onBlur) {
        onBlur(e)
      }
    }

    return (
      <div className="relative flex flex-row flex-wrap items-center justify-between rounded-[3px] bg-white transition-[border-color,box-shadow] duration-200">
        <label
          className={cn(
            'font-heading text-med absolute left-4 top-[26px] z-[1] max-w-[90%] origin-[0] -translate-y-2/4 overflow-hidden overflow-ellipsis whitespace-nowrap bg-[#10354f] px-1.5 py-px transition-[transform,padding,top] duration-150 ease-in-out',
            {
              'h-px w-px [clipPath:inset(50%)] [clip:rect(0,0,0,0)]': noJs,
              'focus-within:text-primary-blue': !hasError,
            }
          )}
        >
          {label}
        </label>
        <input
          type={inputProps.type ?? 'text'}
          {...inputProps}
          onBlur={handleBlur}
          className={cn(
            'font-heading text-med peer h-[3.25rem] w-full rounded-sm border border-solid bg-[#10354f] px-4 py-0 leading-[1.1] text-[#ffffffe6] outline-none transition-[shadow,border-color] duration-200',
            {
              'border-primary-red text-primary-red focus:border-primary-red focus-within:border-primary-red focus-within:text-primary-red focus:ring-primary-red focus-within:shadow-[0_0_0_1px_#ff3333] focus:shadow-[0_0_0_1px_#ff3333]':
                hasError,
              'focus-within:border-primary-blue border-[#00000040] focus-within:shadow-[0_0_0_1px_#41c0ff]':
                !hasError,
            }
          )}
          ref={mergedRef}
        />
        <div
          className={cn(
            'font-ulp text-med pointer-events-none absolute left-4 z-[1] mb-px max-w-[90%] origin-[0] text-ellipsis bg-[#10354f] px-1.5 py-px transition-[transform,top,padding] duration-150 peer-focus:-top-0.5 peer-focus:-translate-x-2 peer-focus:-translate-y-2/4 peer-focus:scale-[0.88]',
            {
              'peer-focus-within:text-primary-blue text-[#ffffff4d]': !hasError,
              'text-primary-red peer-focus-within:text-primary-red': hasError,
              '-top-0.5 -translate-x-2 -translate-y-2/4 scale-[0.88]': filled,
            }
          )}
        >
          {label}
        </div>
      </div>
    )
  }
)

export { Input }
