import type React from 'react'

import { LoginBoxBody } from './login-box-body.tsx'
import { LoginBoxFormFooter } from './login-box-footer.tsx'
import { LoginBoxFormFieldError } from './login-box-form-field-error.tsx'
import { LoginBoxFormField } from './login-box-form-field.tsx'
import { LoginBoxForm } from './login-box-form.tsx'
import { LoginBoxHeader } from './login-box-header.tsx'

type LoginBoxProps = Readonly<{
  children: React.ReactNode
}>

const LoginBoxComponent = ({ children }: LoginBoxProps) => (
  <section className="flex h-auto w-[25rem] flex-col whitespace-normal rounded p-10">
    <div className="relative flex flex-grow flex-col">
      <div className="flex min-h-[33.75rem] flex-col justify-center">
        {children}
      </div>
    </div>
  </section>
)

const LoginBoxNamespace = Object.assign(LoginBoxComponent, {
  Header: LoginBoxHeader,
  Body: LoginBoxBody,
  Form: LoginBoxForm,
  FormField: LoginBoxFormField,
  FormFooter: LoginBoxFormFooter,
  FormFieldError: LoginBoxFormFieldError,
})

export { LoginBoxNamespace as LoginBox, type LoginBoxProps }
