import { cn } from '@mntn-dev/ui-utilities'
import {
  type ButtonHTMLAttributes,
  type RefAttributes,
  forwardRef,
} from 'react'

import { themeTextColorMap } from '@mntn-dev/ui-theme'

type ButtonProps = Readonly<
  ButtonHTMLAttributes<HTMLButtonElement> &
    RefAttributes<HTMLButtonElement> & {
      children: string
      loading?: boolean
    }
>

const LoadingDots = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <circle cx="18" cy="12" r="0" fill="currentColor">
      <animate
        attributeName="r"
        begin=".67"
        calcMode="spline"
        dur="1.5s"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
        repeatCount="indefinite"
        values="0;2;0;0"
      />
    </circle>
    <circle cx="12" cy="12" r="0" fill="currentColor">
      <animate
        attributeName="r"
        begin=".33"
        calcMode="spline"
        dur="1.5s"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
        repeatCount="indefinite"
        values="0;2;0;0"
      />
    </circle>
    <circle cx="6" cy="12" r="0" fill="currentColor">
      <animate
        attributeName="r"
        begin="0"
        calcMode="spline"
        dur="1.5s"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
        repeatCount="indefinite"
        values="0;2;0;0"
      />
    </circle>
  </svg>
)

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, loading, ...buttonProps }, ref) => (
    <button
      type={buttonProps.type ?? 'button'}
      {...buttonProps}
      disabled={loading || buttonProps.disabled}
      ref={ref}
      className={cn(
        `bg-primary-blue ${themeTextColorMap['primary-inverse']} relative flex min-h-[3.25rem] w-full cursor-pointer items-center justify-center rounded border-none px-4 py-1 font-sans font-medium outline-none transition-[background-color,box-shadow] duration-[250ms] focus:shadow-[0_0_0_4px_rgba(15,107,245,0.15)] disabled:pointer-events-none disabled:bg-blue-400`,
        { 'text-base': !loading },
        { 'text-4xl': loading }
      )}
    >
      {loading ? <LoadingDots /> : children}
    </button>
  )
)

export { Button }
