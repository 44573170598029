'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import { type PageBackground, pageBackgroundMap } from '@mntn-dev/ui-theme'

type CenteredLayoutProps = Readonly<{
  background?: PageBackground
  children: React.ReactNode
}>

const CenteredLayout = ({ background, children }: CenteredLayoutProps) => (
  <div
    className={cn('h-fit min-h-screen', {
      [`${pageBackgroundMap[background || 'default']} bg-cover`]: background,
    })}
  >
    <main className="flex h-fit min-h-screen flex-col items-center justify-center p-40">
      {children}
    </main>
  </div>
)

export { CenteredLayout, type CenteredLayoutProps }
