import type React from 'react'
import { forwardRef, type FormHTMLAttributes, type RefAttributes } from 'react'

type LoginBoxFormProps = Readonly<
  FormHTMLAttributes<HTMLFormElement> &
    RefAttributes<HTMLFormElement> & {
      children: React.ReactNode
    }
>

const LoginBoxForm = forwardRef<HTMLFormElement, LoginBoxFormProps>(
  ({ children, ...formProps }: LoginBoxFormProps, ref) => {
    return (
      <form {...formProps} ref={ref}>
        {children}
      </form>
    )
  }
)

export { LoginBoxForm, type LoginBoxFormProps }
